import React, {useEffect, useState} from 'react';
import {
    Box,
    Button, Checkbox,
    Container,
    Paper,
    Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {Add} from "@mui/icons-material";
import {PromptFlow} from "../../models/promptflow/PromptFlow";
import {useAuth} from "react-oidc-context";
import {PromptFlowRepository} from "../../repositories/PromptFlowRepository";
import {AuthHelpers, UserRole} from "../../Utils/AuthHelpers";
import LoadingIndicator from "../../components/LoadingIndicator";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {DataGrid, GridRenderCellParams, GridRowParams} from "@mui/x-data-grid";

const PromptFlowsList = () => {
    const auth = useAuth();
    const promptFlowRepo = new PromptFlowRepository(auth);
    const [ flows, setFlows ] = React.useState<PromptFlow[]>([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    useEffect(() => {
        if (!AuthHelpers.isUserInrole(auth, UserRole.SuperAdmin)) {
            return
        }
        setIsLoading(true);
        getFlows().catch(e => console.error(e));
    }, []);
    
    const getFlows = async () => {
        let flows = await promptFlowRepo.allFlows();        
        if (flows !== undefined) {
            setFlows(flows);
        }
        setIsLoading(false);
    }
    
    const addFlow = () => {
        navigate('/promptflows/new')    
    }
    
    // const editFlow = (id: number) => {
    //     navigate(`/promptflows/edit/${id}`)
    // }

    const rowClick = (params: GridRowParams) => {
        console.log(params);
        navigate({
            pathname: `/promptflows/edit/${params.id}`,
        });
    };
    
    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ p: 3, bgcolor: "background.paper" }}>
               <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                   {/*<History size={24} color={theme.palette.secondary.main} />*/}
                   <Typography variant="h5" sx={{ ml: 1 }} color="secondary">
                       Prompt flows
                   </Typography>
               </Box>
                <Button
                    variant="contained"
                    startIcon={<Add />}
                    sx={{ mb: 3 }}
                    onClick={addFlow}>Nieuwe flow</Button>
                <DataGrid
                    rows={flows}
                    getRowId={(f: PromptFlow) => f.id}
                    rowSelection={true}
                    onRowClick={rowClick}
                    columns={[
                        { field: "isActive", headerName: "Actief", minWidth: 50,
                            renderCell: (params: GridRenderCellParams<any, boolean>) => (
                                params.value
                                    ? <Checkbox
                                        edge="start"
                                        checked={true}
                                        tabIndex={-1}
                                        disableRipple
                                        checkedIcon={<CheckCircleOutlineIcon color="primary" />}

                                    />
                                    : <></>
                            )
                        },
                        { field: "id", headerName: "ID", minWidth: 50 },
                        { field: "name", headerName: "Naam", minWidth: 200 },
                        { field: "description", headerName: "Omschrijving", minWidth: 200 },
                    ]}
                />
                
               {/*<List>*/}
               {/*    { flows.map(flow => (*/}
               {/*        <React.Fragment key={flow.id}>*/}
               {/*            <ListItem key={flow.id} */}
               {/*                      onClick={() => editFlow(flow.id) }*/}
               {/*                      sx={{cursor: "pointer"}}>*/}
               {/*                {flow.isActive*/}
               {/*                    ? <Checkbox*/}
               {/*                        edge="start"*/}
               {/*                        checked={flow.isActive}*/}
               {/*                        tabIndex={-1}*/}
               {/*                        disableRipple*/}
               {/*                        checkedIcon={<CheckCircleOutlineIcon*/}
               {/*                            color="primary" />}*/}
               {/*/!*                    />*!/*/}
               {/*                    : <></>*/}
               {/*                }*/}
               {/*                <ListItemText primary={flow.id + ' ' + flow.name}*/}
               {/*                              secondary={flow.description} />*/}
               {/*            </ListItem>*/}
               {/*        </React.Fragment>*/}
               {/*    ))}*/}
               {/*</List>*/}
                <LoadingIndicator show={isLoading}/>
           </Paper>
       </Container>
    );

}
export default PromptFlowsList;
