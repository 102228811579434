import { unified } from "unified";
import remarkParse from "remark-parse";
import rmarkDocx from "remark-docx";
import { saveAs } from "file-saver";
import rehypeSanitize from "rehype-sanitize";
import rehypeStringify from "rehype-stringify";
import remarkRehype from "remark-rehype";
import {AlignmentType, UnderlineType} from "docx";

export class DocxGenerator {
    static async convert(text: string) {
        // https://github.com/dolanmiu/docx/blob/05a3cf5b439f4ca59565a281bf25ebe013a2601c/demo/2-declaritive-styles.ts
        // https://docx.js.org/#/usage/styling-with-js?id=available-options
        const style = {
            // default: {
            //     paragraph: {
            //         spacing: {
            //             before: 240,
            //             after: 120
            //         },
            //     },
            // },
            default: {
                // heading1: {
                //     run: {
                //         size: 28,
                //         bold: true,
                //         italics: true,
                //         color: "FF0000",
                //     },
                //     paragraph: {
                //         spacing: {
                //             after: 120,
                //         },
                //     },
                // },
                // normal: {
                //     run: {
                //         size: 28,
                //         bold: true,
                //         italics: true,
                //         color: "FF0000",
                //     },
                //     paragraph: {
                //         spacing: {
                //             after: 120,
                //         },
                //     },
                // },
                // hyperlink: {
                //     run: {
                //         color: "FF0000",
                //         underline: {
                //             color: "0000FF",
                //         },
                //     },
                // },
                listParagraph: {
                    run: {
                        bold: false,
                        // color: "#FF0000",
                    },
                },
                document: {
                    run: {
                        size: 26,
                        bold: false,
                        font: "Calibri",
                    },
                    paragraph: {
                        // alignment: AlignmentType.RIGHT,
                        spacing: {
                            before: 240,
                            after: 120,
                        },
                    },
                },
            },
            paragraphStyles: [
            //     {
            //         id: "myWonkyStyle",
            //         name: "My Wonky Style",
            //         basedOn: "Normal",
            //         next: "Normal",
            //         quickFormat: true,
            //         run: {
            //             italics: true,
            //             color: "999999",
            //         },
            //         paragraph: {
            //             spacing: {
            //                 line: 276,
            //             },
            //             indent: {
            //                 left: 720,
            //             },
            //         },
            //     },
            //     {
            //         id: "Heading2",
            //         name: "Heading 2",
            //         basedOn: "Normal",
            //         next: "Normal",
            //         quickFormat: true,
            //         run: {
            //             size: 26,
            //             bold: true,
            //             color: "999999",
            //             underline: {
            //                 type: UnderlineType.DOUBLE,
            //                 color: "FF0000",
            //             },
            //         },
            //         paragraph: {
            //             spacing: {
            //                 before: 240,
            //                 after: 120
            //             },
            //         },
            //     },
            ]
        };
        const docx = await unified()
            .use(remarkParse)
            .use(rmarkDocx,
                {
                    output: "blob",
                    creator: "Rake Voorstellen",
                    title: "Voorstel",
                    styles: style,
                })
            .process(text);
        
        const blob: Blob = await docx.result as Blob;
        
        saveAs(blob, "Voorstel.docx");
    }
}

export class HtmlGenerator {
    static async convert(text: string): Promise<string> {
        const file = await unified()
            .use(remarkParse)
            .use(remarkRehype)
            .use(rehypeSanitize)
            .use(rehypeStringify)
            .process(text)

        return String(file);
    }
}